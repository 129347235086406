.main-content[data-v-81de9e3a] .common_tree_handle .el-tree {
  /* height: 100% !important; */
  height: calc(100vh - 434px) !important;
  overflow-y: scroll;
}
.main-content[data-v-81de9e3a] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 100%;
}
.btn[data-v-81de9e3a] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.shutleBack[data-v-81de9e3a] {
  width: 33%;
  background: #ffffff;
  border: 1px solid #ebeef5;
  border-radius: 4px;
}
.shutleTitle[data-v-81de9e3a] {
  width: 100%;
  background-color: #f5f7fa;
  height: 40px;
  line-height: 40px;
  text-indent: 12px;
  border-bottom: 1px solid #ebeef5;
  font-size: 16px;
}
.shutContent[data-v-81de9e3a] {
  height: 500px;
  overflow: auto;
}
[data-v-81de9e3a] .departTable .avue-crud .el-table {
  height: calc(100vh - 441px) !important;
  max-height: calc(100vh - 441px) !important;
}