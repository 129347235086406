.headers[data-v-bf18c4b0] {
  background: #ffffff;
  padding: 14px 12px;
}
[data-v-bf18c4b0] .customCourses .avue-crud .el-table {
  height: calc(100vh - 316px) !important;
  max-height: calc(100vh - 316px) !important;
}
.headers[data-v-bf18c4b0] .el-button {
  height: 28px;
}
.el-button + .el-button[data-v-bf18c4b0] {
  margin-left: 5px !important;
}
[data-v-bf18c4b0] .bjs-powered-by {
  display: none;
}
.courseCoverImg[data-v-bf18c4b0] {
  width: 80px;
  padding: 5px;
}